<template>
  <div class="grid">
    <WidgetHeading v-if="title" class="title" :content="title">
      <template #right>
        <div class="navigation">
          <button
            :aria-label="t('slider.previous')"
            class="navigation-button navigation-button--prev"
          >
            <ChevronIconLeft class="icon" aria-hidden="true" role="img" />
          </button>
          <button
            :aria-label="t('slider.next')"
            class="navigation-button navigation-button--next"
          >
            <ChevronIconRight class="icon" aria-hidden="true" role="img" />
          </button>
        </div>
      </template>
    </WidgetHeading>
    <div
      v-if="images?.results"
      ref="slides"
      class="slides"
      :data-cursor="t('swipe')"
    >
      <Swiper
        :breakpoints="{
          0: {
            slidesPerView: 1.11,
            spaceBetween: 10,
            slidesOffsetAfter: 20,
            slidesOffsetBefore: 20
          },
          768: {
            slidesPerView: 1.09,
            spaceBetween: 16,
            slidesOffsetAfter: 32,
            slidesOffsetBefore: 32
          },
          1340: {
            slidesPerView: 1.5,
            spaceBetween: 20,
            slidesOffsetAfter: 0,
            slidesOffsetBefore: 0
          }
        }"
        :navigation="
          images.results.length > 1 && {
            prevEl: `#widget-${widgetId} .navigation-button--prev`,
            nextEl: `#widget-${widgetId} .navigation-button--next`
          }
        "
      >
        <SwiperSlide
          v-for="({ id, image }, index) in images?.results"
          :key="id"
          class="slide"
          :swiper-slide-index="index"
        >
          <BaseImage
            :image="image"
            v-bind="
              getImageSizes({
                xs: { w: 335, h: 185 },
                md: { w: 704, h: 396 },
                lg: { w: 900, h: 506 }
              })
            "
          />
        </SwiperSlide>
      </Swiper>
    </div>
    <div v-if="link" class="more-button">
      <BaseButton :base-link="link" color="primary">
        <span v-html="t('see-all-photos')" />
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ChevronIconLeft from '@/assets/icons/chevron-left.svg?component'
import ChevronIconRight from '@/assets/icons/chevron-right.svg?component'
import { PublicLink } from '@/service/__generated-api'

const props = defineProps<{
  title: string
  link: PublicLink | null
  selectedGallery: string
  widgetId: string
}>()

const { t } = useI18n()

const slides = ref<HTMLElement>()

const { data: images } = useAsyncData(() =>
  useApi().publicGalleriesGalleryImagesList({
    gallery: [props.selectedGallery],
    page_size: 12
  })
)
</script>

<style lang="scss" scoped>
.title {
  @include media-column(
    (
      xs: 4,
      md: 8,
      lg: 12
    )
  );
  margin-bottom: rem(16px);

  @include media-up(md) {
    margin-bottom: rem(24px);
  }
}

.slides {
  @include media-column(
    (
      xs: all,
      md: all,
      lg: 12
    )
  );
  position: relative;
  overflow: hidden;
}

.more-button {
  @include media-column(
    (
      xs: 4,
      md: 8,
      lg: 12
    )
  );
  display: flex;
  justify-content: center;
  margin-top: rem(40px);

  @include media-up(md) {
    margin-top: rem(56px);
  }

  @include media-up(lg) {
    margin-top: rem(64px);
  }
}

.navigation {
  display: flex;
  gap: rem(8px);
  align-items: center;
  justify-content: flex-end;
}

.navigation-button {
  @include focus-visible;
  display: flex;
}

.icon {
  @include media-up(lg) {
    width: rem(5px);
  }
}

.slide {
  :deep(img) {
    pointer-events: none;
    user-select: none;
  }
}
</style>
