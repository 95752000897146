<template>
  <BasePopup :active="activeImageIndex !== null" @close="$emit('close')">
    <template #header>
      <span v-if="activeImageIndex !== null" class="t-display wildwaffle">
        {{ activeImageIndex + 1 }}/{{ imagesCount ?? images.length }}
      </span>
    </template>
    <template #default>
      <div v-if="activeImageIndex !== null" class="popup-content">
        <div class="navigation">
          <button
            class="navigation-button navigation-button--prev"
            :disabled="!activeImageIndex"
            :aria-label="t('slider.previous')"
            @click="$emit('update:activeImageIndex', activeImageIndex - 1)"
          >
            <ArrowLeftIcon class="icon" aria-hidden="true" role="img" />
          </button>
          <button
            class="navigation-button navigation-button--next"
            :disabled="activeImageIndex === images.length - 1"
            :aria-label="t('slider.next')"
            @click="$emit('update:activeImageIndex', activeImageIndex + 1)"
          >
            <ArrowRightIcon class="icon" aria-hidden="true" role="img" />
          </button>
        </div>
        <div
          v-if="selectedImage"
          class="image-container"
          :style="getAspectRatioStyle(selectedImage)"
        >
          <BaseImage :image="selectedImage" />
        </div>
      </div>
    </template>
  </BasePopup>
</template>

<script lang="ts" setup>
import ArrowLeftIcon from '@/assets/icons/arrow-left.svg'
import ArrowRightIcon from '@/assets/icons/arrow-right.svg'
import { PublicGalleryImage } from '@/service/__generated-api'

const { t } = useI18n()

const props = defineProps<{
  widgetId: string
  activeImageIndex: null | number
  images: PublicGalleryImage[]
  imagesCount?: number
}>()

defineEmits<{
  close: []
  'update:activeImageIndex': [newIndex: number | null]
}>()

const selectedImage = computed(() =>
  props.activeImageIndex === null
    ? null
    : props.images[props.activeImageIndex].image
)
</script>

<style scoped lang="scss">
.popup-content {
  display: flex;
  flex-direction: column;
  gap: rem(24px);
  padding-bottom: rem(82px);

  @include media-up(lg) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.image-container {
  width: 100%;
  max-width: rem(909px);
}

.navigation,
.navigation-button {
  @include focus-visible;
  display: flex;
}

.navigation {
  gap: rem(4px);
  align-items: flex-start;
}

.icon {
  @include size(rem(44px));

  @include media-up(lg) {
    @include size(rem(52px));
  }
}

.t-display {
  margin-bottom: -0.125em;
}
</style>
